import ProGalleryStore from './ProGalleryStore';
import { experimentsWrapper } from 'photography-client-lib/dist/src/sdk/experimentsWrapper';
import translationUtils from 'photography-client-lib/dist/src/utils/translationUtils';
import galleryWixCodeApi from './wixCodeApi';
import SentryReporter from './SentryReporter';
import { utils } from '@wix/pro-gallery-tpa-wrapper/dist/src/utils';

let context;
const pgTiming = {};
const sentryReporter = new SentryReporter();
export function initAppForPage(
  initAppParam,
  platformUtilitiesApi,
  scopedGlobalSdkApis,
  platformServices,
) {
  context = {
    ...initAppParam,
    platformServices,
  };
  sentryReporter.init(initAppParam, scopedGlobalSdkApis, platformServices);
}

function controller(controllerData) {
  const {
    appParams: { instanceId },
    config: { externalId },
    compId,
    // $w,
    setProps,
    wixCodeApi,
    warmupData,
  } = controllerData;

  const { msid, platformServices } = context;

  const isSSR = wixCodeApi.window.rendering.env === 'backend';
  const proGalleryStore = new ProGalleryStore(
    {
      externalId,
      compId,
      instanceId,
      msid,
    },
    wixCodeApi,
    platformServices,
    isSSR,
  );

  const getMoreItems = from => {
    if (proGalleryStore.itemsSrc !== 'wixCode') {
      proGalleryStore.getItemsFromServer(from).then(items => {
        proGalleryStore.addItems(items, from);
        setProps({
          items: proGalleryStore.getItems(),
        });
      });
    }
  };

  if (warmupData) {
    proGalleryStore.setItems(warmupData.items);
    proGalleryStore.totalItemsCount = warmupData.totalItemsCount;
    experimentsWrapper.setExperiments(warmupData.experiments);
  }

  const { baseUrls } = context;

  const onItemClicked = itemData => {
    galleryWixCodeApi.onItemClicked(itemData);
    proGalleryStore.onItemClicked(itemData);
  };

  const onItemChanged = itemData => {
    galleryWixCodeApi.onItemChanged(itemData);
  };

  const setHeight = height => {
    setProps({
      dimensions: {
        height,
      },
    });
  };

  return {
    //page ready return a promise, which its resolved value will be warmup data
    pageReady: () => {
      try {
        pgTiming[Date.now()] = 'Page Ready';
        const itemsPromise = proGalleryStore.loadInitialItems(isSSR);
        const translationsPromise = proGalleryStore.getTranslations();
        const experimentsPromise = proGalleryStore.getExperiments();

        const viewportPromise = isSSR
          ? wixCodeApi.window.getComponentViewportState(compId)
          : Promise.resolve({ in: true });

        return viewportPromise.then(viewPortState => {
          pgTiming[Date.now()] = 'Set Props';
          if (!viewPortState.in) {
            //render none
            setProps({
              notInView: true,
              pgTiming,
            });
            proGalleryStore.onAppLoaded();
            return null;
          }

          return Promise.all([
            itemsPromise,
            experimentsPromise,
            translationsPromise,
          ])
            .catch(e => {
              sentryReporter.report(e);
              console.error('Waiting for promises failed', e);
            })
            .then(([itemsRaw, experimentsRaw, translationsRaw]) => {
              translationUtils.setTranslations &&
                translationUtils.setTranslations(translationsRaw);
              experimentsWrapper.setExperiments(experimentsRaw);

              pgTiming[Date.now()] = 'Set Props';
              // pgTiming.setProps = Date.now();

              const locationQuery = wixCodeApi.location.query;
              const queryParams = JSON.stringify({
                ...locationQuery,
              });
              if (utils.isVerbose()) {
                console.log('query: ', JSON.stringify(locationQuery));
              }

              const fullscreenUrl =
                wixCodeApi.location.baseUrl + '/fullscreen-page';

              setProps({
                baseUrl: proGalleryStore.getBaseUrl(),
                fullscreenUrl,
                scrollTo: wixCodeApi.window.scrollTo,
                onItemClicked,
                onItemChanged,
                queryParams,
                experiments: experimentsWrapper.experiments,
                translations: translationsRaw,
                setHeight,
                setItems: proGalleryStore.setItems,
                cssBaseUrl: baseUrls.santaWrapperBaseUrl,
                items: proGalleryStore.getItems(),
                totalItemsCount: proGalleryStore.totalItemsCount,
                getMoreItems: getMoreItems,
                manualStyleParams: galleryWixCodeApi.manualStyleParams,
                gallerySettings: proGalleryStore.settings,
                galleryId: proGalleryStore.galleryId,
                dateCreated: proGalleryStore.dateCreated,
                onAppLoaded: proGalleryStore.onAppLoaded,
                pgTiming,
                instanceId,
                onNavigate: url => {
                  wixCodeApi.location.to(url);
                },
              });

              if (isSSR) {
                proGalleryStore.onAppLoaded();
              }

              return {
                //return value here 'returns' as warmup data
                items: proGalleryStore.getItems(),
                totalItemsCount: proGalleryStore.totalItemsCount,
                experiments: experimentsWrapper.experiments,
              };
            });
        });
      } catch (e) {
        console.error(e);
        sentryReporter.report(e);
      }
    },
    updateConfig: ($w, updatedConfig) => {
      try {
        if (utils.isVerbose()) {
          console.log('got update config!');
        }
      } catch (e) {
        sentryReporter.report(e);
      }
    },
    exports: () => {
      try {
        const setNewItems = items => {
          setProps({
            wixCodeItems: items,
          });
        };
        return galleryWixCodeApi.generateApi({
          proGalleryStore,
          setNewItems,
        });
      } catch (e) {
        sentryReporter.report(e);
      }
    },
  };
}

export function createControllers(controllerConfigs) {
  try {
    // pgTiming.createControllers = Date.now();
    pgTiming[Date.now()] = 'Create Controllers';
    return controllerConfigs.map(function(config) {
      return Promise.resolve(controller(config));
    });
  } catch (e) {
    sentryReporter.report(e);
  }
}

export const wrappedFunctions = {
  initAppForPage: initAppForPage,
  createControllers: createControllers,
};
